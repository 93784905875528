/* @flow */
import React, { Component } from 'react';
import type { Node } from 'react';
import InfoButton from '@Components/InfoButton';
import InfoModal from '@Components/InfoModal';
import classNames from 'classnames';
import styles from './index.module.scss';

type Props = {
	children: Node,
	title: string,
	text: string,
	info?: Object,
	visible?: boolean,
	icon: string
};

type State = {
	visible: boolean,
	reveal: boolean,
	modal: boolean
};

class FormSection extends Component<Props, State> {
	static defaultProps = {
		info: null,
		visible: false
	};

	constructor(props: Props) {
		super(props);

		const { visible } = this.props;

		this.state = {
			visible: visible || false,
			reveal: false,
			modal: false
		};
	}

	show = () => {
		// this.setState({ visible: true }, () => this.reveal());
		this.setState({ visible: true });
	};

	// reveal = () => {
	// 	setTimeout(() => {
	// 		this.setState({ reveal: true });
	// 	}, 100);
	// };

	showInfoModal(flag: boolean) {
		this.setState({ modal: flag });
	}

	render() {
		const { children, title, text, info, icon } = this.props;
		const { modal, visible, reveal } = this.state;

		const classes = classNames({
			[styles.formSection]: true,
			[styles.show]: visible,
			reveal
		});

		return (
			visible && (
				<section className={classes}>
					<img className={styles.icon} src={icon} alt="" />
					<img className={styles.iconBg} src={icon} alt="" />
					<h2 className={styles.title}>
						{title}
						{info && (
							<div className={styles.info}>
								<InfoButton
									onClick={() => {
										this.showInfoModal(true);
									}}
								/>
							</div>
						)}
					</h2>
					{info && modal && (
						<InfoModal
							title={info.title}
							text={info.text}
							onClose={() => {
								this.showInfoModal(false);
							}}
						/>
					)}
					<p className={styles.text}>{text}</p>
					{children}
				</section>
			)
		);
	}
}

export default FormSection;
