/* @flow */
import React, { useContext } from 'react';
import Form from '@Containers/Form';
import Products from '@Containers/Products';
import Notification from '@Components/Notification';
import styles from './App.module.scss';

const App = () => {
	return (
		<>
			<div className={styles.wrapper}>
				<Form />
				<Products />
				<Notification
					title="Uppdaterad rekommendation"
					text="Nya uppgifter har lagts till så vi har uppdaterat din rekommendation."
				/>
			</div>
		</>
	);
};

export default App;
