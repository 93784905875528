/* @flow */
import React, { Component } from 'react';
import Button from '@Components/Button';
import cx from 'classnames';
import styles from './index.module.scss';

type Props = {
	// onUpdate: Function,
	onConfirm: Function
};

type State = {
	value: string,
	valid: boolean,
	clicked: boolean,
	changed: boolean,
	currentValue: string
};

class AngleField extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			value: '',
			valid: false,
			clicked: false,
			changed: false,
			currentValue: ''
		};
	}

	handleChange(e: SyntheticInputEvent<HTMLInputElement>) {
		console.log('handleChange', e.target.value, e.target.checked);

		this.setState({ value: e.target.value }, () => {
			this.validate();
		});
	}

	validate() {
		// const { onUpdate } = this.props;
		const { currentValue, value, clicked } = this.state;

		console.log('validate: ', value);

		if (value !== '') {
			if (clicked && currentValue !== value) {
				this.setState({ valid: true, changed: true });
			} else {
				this.setState({ valid: true });
			}
		}

		// onUpdate({ angle: value });
	}

	confirm() {
		const { onConfirm } = this.props;
		const { value } = this.state;

		this.setState({
			clicked: true,
			changed: false,
			currentValue: value
		});

		onConfirm({
			angle: value
		});
	}

	render() {
		const { onConfirm } = this.props;
		const { value, valid, clicked, changed } = this.state;

		let button;
		if (clicked && changed) {
			button = (
				<Button
					text="Uppdatera"
					icon="update"
					className={cx(
						styles.confirm,
						valid ? styles.active : styles.inactive
					)}
					onClick={() => {
						this.confirm();
					}}
				/>
			);
		} else if (clicked) {
			button = (
				<Button
					text="Taklutning"
					icon="checkmark"
					className={cx(styles.confirm, styles.inactive)}
					onClick={() => {
						this.confirm();
					}}
				/>
			);
		} else {
			button = (
				<Button
					text="Fortsätt"
					icon="continue"
					className={cx(
						styles.confirm,
						valid ? styles.active : styles.disabled
					)}
					onClick={() => {
						this.confirm();
					}}
				/>
			);
		}

		return (
			<div className={styles.angleField}>
				<div className={styles.angle}>
					<label className={styles.label} htmlFor="fields_flat">
						<span className={styles.labelText}>Platt</span>
						<span className={styles.labelValue}>0-5°</span>
						<input
							id="fields_flat"
							type="radio"
							value="platt"
							name="angle"
							onChange={e => {
								this.handleChange(e);
							}}
							className={cx(styles.angleInput, styles.flat)}
						/>
						<svg
							width="94"
							height="66"
							viewBox="0 0 94 66"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<g opacity="0.1">
								<path
									d="M86.0347 67.2205V2.52471C86.0347 1.13044 85.0064 0 83.6081 0H20.3084C18.9101 0 17.6711 1.13044 17.6711 2.52471V20.5133H10.6555H10.4973C9.09901 20.5133 7.85992 21.6437 7.85992 23.038V67.2205C3.74512 67.2205 0 70.7599 0 75.1103C0 79.4607 3.60207 83 7.96531 83H86.1404C90.5033 83 94 79.4607 94 75.1103C94 70.7599 90.4657 67.2205 86.0347 67.2205ZM86.1404 77.9506H7.96531C6.39453 77.9506 5.11683 76.6765 5.11683 75.1103C5.11683 73.544 6.39453 72.27 7.96531 72.27H86.1404C87.7112 72.27 88.9889 73.544 88.9889 75.1103C88.9889 76.6765 87.7109 77.9506 86.1404 77.9506ZM70.2098 67.2205H59.7653V41.3422H70.2098V67.2205ZM22.7353 36.2928H27.7993V67.2205H22.7353V36.2928ZM32.8633 48.9388L51.2411 67.2205H32.8633V48.9388ZM80.9707 67.2205H75.2737V38.8175C75.2737 37.4232 74.2454 36.2928 72.8472 36.2928H59.7653V23.038C59.7653 21.6437 58.737 20.5133 57.3387 20.5133H51.2199V5.04943H80.9707V67.2205ZM46.1559 20.5133H41.0919V5.04943H46.1559V20.5133ZM36.0283 20.5133H22.7353V5.04943H36.0283V20.5133ZM54.7014 25.5627V63.6354L32.863 41.7862V33.7681C32.863 32.3738 31.8347 31.2433 30.4364 31.2433H22.4312L16.7535 25.5627H54.7014ZM12.9236 28.9894L17.6726 33.7406C17.6723 33.7498 17.6711 33.7589 17.6711 33.7681V67.2205H12.9236V28.9894Z"
									fill="#333333"
								/>
							</g>
						</svg>

						<span className={styles.labelInfo}>
							Ofta modern stil eller funkis
						</span>
					</label>
					<label className={styles.label} htmlFor="fields_angled">
						<span className={styles.labelText}>Vinklat</span>
						<span className={styles.labelValue}>5-45°</span>
						<input
							id="fields_angled"
							type="radio"
							value="vinklat"
							name="angle"
							onChange={e => {
								this.handleChange(e);
							}}
							className={cx(styles.angleInput, styles.angled)}
						/>
						<svg
							width="94"
							height="68"
							viewBox="0 0 94 68"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<g opacity="0.1">
								<path
									d="M93.4079 32.0494L70.4482 1.02483C69.9712 0.380416 69.215 0 68.4106 0H25.9893C25.9747 0 25.9605 0.00220239 25.9459 0.00251705C25.8773 0.00377566 25.8087 0.00818145 25.7407 0.0144745C25.7172 0.0169918 25.6935 0.0185648 25.6698 0.0213967C25.5857 0.0317803 25.5018 0.046254 25.4187 0.0657625C25.4126 0.0670211 25.4066 0.067651 25.4009 0.0692242C25.308 0.09125 25.2159 0.118311 25.1254 0.151349C25.1223 0.152608 25.1194 0.153866 25.1169 0.155124C25.0356 0.184702 24.9556 0.219314 24.8765 0.257702C24.8518 0.269973 24.8278 0.283818 24.8035 0.296719C24.7487 0.325982 24.6943 0.355874 24.6412 0.389542C24.6099 0.408736 24.5801 0.430133 24.5495 0.4509C24.5238 0.46852 24.4973 0.483937 24.472 0.502817C24.4524 0.517291 24.4353 0.533967 24.4163 0.548756C24.3869 0.571726 24.3581 0.595325 24.3299 0.620183C24.2844 0.659515 24.2414 0.700105 24.1993 0.741954C24.1775 0.763665 24.1554 0.785376 24.1342 0.807717C24.0836 0.862467 24.0358 0.919104 23.9909 0.976685C23.9827 0.987698 23.9725 0.996824 23.9643 1.00815L23.9453 1.03332C23.9418 1.03804 23.9384 1.04245 23.9349 1.04717L0.505202 32.1589C-0.0692003 32.9219 -0.161244 33.942 0.267344 34.7947C0.695932 35.6471 1.57209 36.1855 2.53016 36.1855H5.13965V70.4828C5.13965 71.8729 6.37796 73 7.77538 73H86.5343C87.9318 73 88.9594 71.8729 88.9594 70.4828V36.1855H91.4633C91.4633 36.1855 91.4677 36.1855 91.4696 36.1855C92.8673 36.1855 94 35.0584 94 33.6683C94 33.0519 93.7773 32.4874 93.4079 32.0494ZM31.0767 67.9655H20.6387V51.6035H31.0767V67.9655ZM41.5146 67.9655H36.1375V49.0863C36.1375 47.6962 35.1098 46.5691 33.7124 46.5691H18.2136C16.8162 46.5691 15.5779 47.6962 15.5779 49.0863V67.9655H10.2008V36.1855H41.5146V67.9655ZM44.1503 31.151H7.7757H7.58782L25.9893 6.71597L44.3907 31.151H44.1503ZM83.8989 67.9655H46.5754V36.1855H83.8989V67.9655ZM50.7142 31.151L31.0466 5.03478H67.1312L86.4584 31.151H50.7142Z"
									fill="#333333"
								/>
								<path
									d="M75.3584 46.8837C75.3584 45.4935 74.2254 44.3665 72.828 44.3665H57.3292C55.9318 44.3665 54.7988 45.4935 54.7988 46.8837V57.2673C54.7988 58.6574 55.9318 59.7845 57.3292 59.7845H72.828C74.2254 59.7845 75.3584 58.6574 75.3584 57.2673V46.8837ZM59.8596 49.4009H70.2976V54.75H59.8596V49.4009Z"
									fill="#333333"
								/>
							</g>
						</svg>
						<span className={styles.labelInfo}>
							Ofta klassisk stil, brutna tak
						</span>
					</label>
				</div>
				<span className={styles.labelInfo}>
					Ett platt tak kan innebära bygglov - lugn, vi hjälper dig med det.
				</span>
				{button}
			</div>
		);
	}
}

export default AngleField;
