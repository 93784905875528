/* @flow */
const blender: Function = (color1: string, color2: string, percent: number) => {
	const generateHex: Function = (rc: number, gc: number, bc: number) => {
		let r: string = rc.toString(16);
		let g: string = gc.toString(16);
		let b: string = bc.toString(16);

		while (r.length < 2) {
			r = `0${r}`;
		}
		while (g.length < 2) {
			g = `0${g}`;
		}
		while (b.length < 2) {
			b = `0${b}`;
		}

		return `#${r}${g}${b}`;
	};

	const mix: Function = (start: number, end: number, prcnt: number) =>
		start + prcnt * (end - start);

	const red1: number = parseInt(color1[1] + color1[2], 16);
	const green1: number = parseInt(color1[3] + color1[4], 16);
	const blue1: number = parseInt(color1[5] + color1[6], 16);

	const red2: number = parseInt(color2[1] + color2[2], 16);
	const green2: number = parseInt(color2[3] + color2[4], 16);
	const blue2: number = parseInt(color2[5] + color2[6], 16);

	const red: number = Math.round(mix(red1, red2, percent));
	const green: number = Math.round(mix(green1, green2, percent));
	const blue: number = Math.round(mix(blue1, blue2, percent));

	return generateHex(red, green, blue);
};

export default blender;
