/* @flow */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng
} from 'react-places-autocomplete';

import cx from 'classnames';
import styles from './index.module.scss';

/////////////////////////////////////////////////////////////////////////////////////////////
// TYPES
/////////////////////////////////////////////////////////////////////////////////////////////
type Props = {
	address?: string,
	postalCode?: string,
	autoCompleteAlign: string,
	addressChange: Function
};
type State = {
	isValid: boolean,
	address: string,
	addressComponents: Object,
	defaultAddress: string
};
type RefObject = { current: any };

/////////////////////////////////////////////////////////////////////////////////////////////
// CLASS
/////////////////////////////////////////////////////////////////////////////////////////////
class SearchField extends Component<Props, State> {
	static defaultProps = {
		address: '',
		postalCode: '',
		autoCompleteAlign: 'bottom'
	};

	static propTypes = {
		autoCompleteAlign: PropTypes.oneOf(['bottom', 'top'])
	};

	minWordSearchLength: number = 2;
	ref: RefObject;

	constructor(props: Props) {
		super(props);

		this.state = {
			isValid: true,
			address: props.address ? props.address : '',
			addressComponents: {},
			defaultAddress: props.address ? props.address : ''
		};

		this.ref = React.createRef();
	}

	componentDidMount() {
		const { address } = this.state;

		if (address !== '') {
			this.handleSelect(address);
		}
	}

	componentDidUpdate(prevProps: Props) {
		const { address, postalCode } = this.props;
		const { address: prevAddress, postalCode: prevPostalCode } = prevProps;
		const { defaultAddress, addressComponents } = this.state;

		if (postalCode && postalCode !== prevPostalCode) {
			let newAddress = '';

			if (addressComponents.street && addressComponents.street.long_name) {
				newAddress += addressComponents.street.long_name;

				if (addressComponents.number && addressComponents.number.long_name) {
					newAddress += ' ';
					newAddress += addressComponents.number.long_name;
					newAddress += ', ';
				} else {
					newAddress += ', ';
				}
			}

			newAddress += postalCode;
			newAddress += ' ';

			if (addressComponents.city && addressComponents.city.long_name) {
				newAddress += addressComponents.city.long_name;

				if (addressComponents.country && addressComponents.country.long_name) {
					newAddress += ', ';
					newAddress += addressComponents.country.long_name;
				}
			}

			this.handleSelect(newAddress);
		}

		if (address !== prevAddress && defaultAddress !== address) {
			this.setState({ address });
		}
	}

	handleChange(address: string) {
		console.log('handleChange:', address);
		this.setState({ address });
	}

	inputBlur() {
		const { address } = this.state;

		this.handleSelect(address);
	}

	async handleSelect(address: string) {
		const { addressChange } = this.props;

		// Also defined in PlacesAutocomplete component. User needs to defined more then 2 letter - then we use the first in list, if no other has been used.
		if (address.length <= this.minWordSearchLength) {
			addressChange(address, { lat: null, lng: null });
			return;
		}

		const addressComponent = await geocodeByAddress(address)
			.then(async results => {
				console.log('Address result:', results[0].address_components);

				const ac = results[0].address_components;
				const street = ac.find(obj => obj.types[0] === 'route');
				const number = ac.find(obj => obj.types[0] === 'street_number');
				const zip = ac.find(obj => obj.types[0] === 'postal_code');
				const city = ac.find(obj => obj.types[0] === 'postal_town');
				const country = ac.find(obj => obj.types[0] === 'country');

				this.setState({
					addressComponents: { street, number, zip, city, country }
				});

				return getLatLng(results[0]);
			})
			.catch(err => {
				console.log('Address not found:', err);
				this.setState({ isValid: false });
				addressChange(address, { lat: null, lng: null });
			});

		// If null don't bother fetch from API
		if (!addressComponent) return;

		// Set address state so input field shows address to search for
		this.setState({ address, isValid: true });

		// Dispatch changes
		addressChange(address, addressComponent);

		if (this.ref && this.ref.current) {
			this.ref.current.blur();
		}
	}

	/////////////////////////////////////////////////////////////////////////////////////////////
	// RENDER
	/////////////////////////////////////////////////////////////////////////////////////////////
	render() {
		const { isValid, address } = this.state;
		const { autoCompleteAlign } = this.props;

		console.log('isValid:', isValid);

		// Styles state
		const searchFieldValid = cx(
			styles.searchField,
			isValid === true ? styles.valid : styles.invalid
		);
		const wrapperActive = cx(
			styles.wrapper,
			autoCompleteAlign === 'bottom'
				? styles.wrapper_active_autocomplete_bottom
				: styles.wrapper_active_autocomplete_top
		);
		const autoComplete = cx(
			styles.autocomplete,
			autoCompleteAlign === 'bottom'
				? styles.autocomplete_bottom
				: styles.autocomplete_top
		);
		const autoCompleteActive = cx(autoComplete, styles.autocomplete_active);
		const suggestionActive = cx(
			styles.suggestion_item,
			styles.suggestion_item_active
		);

		return (
			<>
				<div
					className={cx(styles.icon, isValid ? styles.valid : styles.invalid)}
				>
					<svg
						width="11"
						height="14"
						viewBox="0 0 11 14"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M6.71382 12.6254C9.4585 9.53257 11 7.79554 11 5.25C11 2.35051 8.53757 0 5.5 0C2.46243 0 0 2.35051 0 5.25C0 7.79554 1.5415 9.53257 4.28618 12.6254C4.66783 13.0554 5.07274 13.5117 5.5 14C5.92726 13.5117 6.33217 13.0554 6.71382 12.6254ZM5.5 8C6.88071 8 8 6.88071 8 5.5C8 4.11929 6.88071 3 5.5 3C4.11929 3 3 4.11929 3 5.5C3 6.88071 4.11929 8 5.5 8Z"
							fill="#666666"
						/>
					</svg>
				</div>
				<PlacesAutocomplete
					value={address}
					highlightFirstSuggestion
					searchOptions={{
						types: ['address'],
						componentRestrictions: { country: 'se' }
					}}
					shouldFetchSuggestions={address.length >= this.minWordSearchLength}
					onChange={(adress: string) => {
						this.handleChange(adress);
					}}
					onSelect={(adress: string) => {
						this.handleSelect(adress);
					}}
				>
					{({ getInputProps, suggestions, getSuggestionItemProps }) => (
						<div className={searchFieldValid}>
							<div
								className={suggestions.length ? wrapperActive : styles.wrapper}
							>
								<div className={styles.box}>
									<input
										id="searchField"
										tabIndex={0}
										ref={this.ref}
										{...getInputProps({
											placeholder: 'Fyll i din adress',
											className: styles.input
										})}
										type="text"
										onBlur={() => {
											this.inputBlur();
										}}
									/>
								</div>
								<div
									className={
										suggestions.length ? autoCompleteActive : autoComplete
									}
								>
									{suggestions.map(suggestion => {
										const className = suggestion.active
											? suggestionActive
											: styles.suggestion_item;
										return (
											<div
												{...getSuggestionItemProps(suggestion, {
													className
												})}
											>
												<span className={styles.suggestion}>
													{suggestion.description}
												</span>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					)}
				</PlacesAutocomplete>
				<label className={styles.edit} htmlFor="searchField">
					<svg
						width="16"
						height="16"
						viewBox="0 0 16 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M15.3486 0.539254L15.3292 0.521688C14.9594 0.185285 14.4799 0 13.9791 0C13.4178 0 12.8788 0.236606 12.5003 0.649095L5.34448 8.44934C5.27926 8.52042 5.22979 8.6043 5.1992 8.69562L4.35778 11.2072C4.26049 11.4975 4.30932 11.8186 4.48836 12.0661C4.66884 12.3155 4.95958 12.4644 5.26618 12.4644H5.26622C5.39884 12.4644 5.52846 12.4373 5.6514 12.3837L8.08765 11.3225C8.17622 11.284 8.25568 11.2273 8.32086 11.1562L15.4768 3.35605C16.2214 2.54442 16.164 1.2809 15.3486 0.539254ZM5.96992 10.7432L6.46367 9.26945L6.50531 9.22405L7.44111 10.0751L7.39947 10.1205L5.96992 10.7432ZM14.4565 2.42811L8.3731 9.05926L7.43729 8.20823L13.5207 1.57704C13.6397 1.44734 13.8025 1.37588 13.9792 1.37588C14.1342 1.37588 14.2826 1.4333 14.3975 1.53778L14.4168 1.55534C14.6694 1.78507 14.6872 2.1766 14.4565 2.42811Z"
							fill="#DDDDDD"
						/>
						<path
							d="M13.9612 6.34684C13.5797 6.34684 13.2703 6.65485 13.2703 7.03478V12.8753C13.2703 13.8399 12.482 14.6248 11.5132 14.6248H3.13902C2.1701 14.6248 1.38188 13.8399 1.38188 12.8753V4.6052C1.38188 3.64053 2.17014 2.85568 3.13902 2.85568H9.20026C9.58184 2.85568 9.8912 2.54766 9.8912 2.16774C9.8912 1.78781 9.58184 1.4798 9.20026 1.4798H3.13902C1.40813 1.4798 0 2.88187 0 4.6052V12.8752C0 14.5986 1.40818 16.0006 3.13902 16.0006H11.5131C13.244 16.0006 14.6521 14.5986 14.6521 12.8752V7.03478C14.6522 6.65485 14.3428 6.34684 13.9612 6.34684Z"
							fill="#DDDDDD"
						/>
					</svg>
				</label>
			</>
		);
	}
}

export default SearchField;
