/* @flow */
import React, { Component } from 'react';
import cx from 'classnames';
import styles from './index.module.scss';

type Props = {
	id?: string,
	min?: number,
	max?: number,
	onChange: Function,
	onGrab?: Function,
	onRelease?: Function,
	className?: string,
	startValue?: string
};

type State = {
	value: string,
	isActive: boolean
};

class RangeSlider extends Component<Props, State> {
	static defaultProps = {
		startValue: '50',
		onGrab: null,
		onRelease: null,
		className: '',
		min: 0,
		max: 100,
		id: ''
	};

	constructor(props: Props) {
		super(props);

		const { startValue } = this.props;

		this.state = {
			value: startValue || '0',
			isActive: false
		};
	}

	activate() {
		this.setState({ isActive: true });
	}

	handleMouseDown(e: SyntheticInputEvent<HTMLInputElement>) {
		const { onGrab } = this.props;
		const { isActive } = this.state;

		if (onGrab) onGrab(e);
		if (!isActive) this.activate();
		this.handleChange(e);
	}

	handleChange(e: SyntheticInputEvent<HTMLInputElement>) {
		this.setState({ value: e.target.value });
		const { onChange } = this.props;
		onChange(e.target.value);
	}

	render() {
		const { id, min, max, onGrab, onRelease, className } = this.props;
		const { value, isActive } = this.state;

		return (
			<input
				id={id}
				min={min}
				max={max}
				type="range"
				value={value}
				className={cx(
					styles.rangeSlider,
					className,
					isActive ? styles.active : styles.inactive
				)}
				onMouseDown={e => {
					this.handleMouseDown(e);
				}}
				onChange={e => {
					this.handleChange(e);
				}}
				onMouseUp={e => {
					if (onRelease) onRelease(e);
				}}
				onTouchEnd={e => {
					if (onRelease) onRelease(e);
				}}
			/>
		);
	}
}

export default RangeSlider;
