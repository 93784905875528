/* @flow */
import React, { Component } from 'react';
import type { Node } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './index.module.scss';

type Props = {
	children?: Node,
	onClick: Function,
	text: string,
	type: string,
	icon: string,
	className?: string,
	isEnabled?: boolean
};

class Button extends Component<Props> {
	static propTypes = {
		type: PropTypes.oneOf(['primary', 'secondary', 'contrast']),
		icon: PropTypes.oneOf(['none', 'send', 'continue', 'update', 'checkmark'])
	};

	static defaultProps = {
		children: null,
		type: 'primary',
		icon: 'none',
		className: '',
		isEnabled: true
	};

	render() {
		const {
			children,
			onClick,
			text,
			type,
			icon,
			className,
			isEnabled
		} = this.props;

		let svg;
		switch (icon) {
			case 'send':
				svg = (
					<svg
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M19.1534 0.032816L0.444139 6.14628C-0.0842372 6.31894 -0.160647 7.03764 0.320231 7.31723L7.39066 11.4286C7.61372 11.5583 7.8925 11.5426 8.09959 11.3888L10.0871 9.91278L8.61112 11.9003C8.45733 12.1075 8.44168 12.3863 8.57136 12.6093L12.6827 19.6797C12.9626 20.161 13.6811 20.0838 13.8537 19.5558L19.9671 0.84651C20.1311 0.344141 19.6534 -0.130648 19.1534 0.032816ZM13.0743 17.7864L9.90049 12.3282L13.7275 7.17489C13.9182 6.91804 13.892 6.56027 13.6658 6.33406C13.4396 6.10786 13.0818 6.08157 12.8249 6.27229L7.67162 10.0994L2.2135 6.92554L18.3457 1.65416L13.0743 17.7864ZM6.77369 14.1385L2.48999 18.4222C2.23806 18.6741 1.82956 18.6742 1.57763 18.4222C1.32565 18.1703 1.32565 17.7618 1.57763 17.5098L5.86133 13.2262C6.11334 12.9743 6.52184 12.9742 6.77369 13.2262C7.02566 13.4781 7.02566 13.8866 6.77369 14.1385ZM1.11457 14.3006C0.862599 14.0487 0.862599 13.6402 1.11457 13.3883L2.80486 11.6979C3.05679 11.446 3.46529 11.446 3.71722 11.6979C3.96919 11.9499 3.96919 12.3584 3.71722 12.6103L2.02693 14.3006C1.77504 14.5525 1.3665 14.5526 1.11457 14.3006ZM8.30193 16.2826C8.5539 16.5345 8.5539 16.943 8.30193 17.195L6.6116 18.8853C6.48563 19.0113 6.32052 19.0743 6.15544 19.0743C5.58581 19.0743 5.29171 18.3804 5.69928 17.9729L7.38961 16.2826C7.6415 16.0306 8.05 16.0306 8.30193 16.2826Z"
							fill="#333333"
						/>
					</svg>
				);
				break;
			case 'continue':
				svg = (
					<svg
						width="14"
						height="18"
						viewBox="0 0 14 18"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M13.2222 5.47824H9.98122L13.0712 1.24591C13.1563 1.12931 13.2077 0.991255 13.2195 0.847074C13.2313 0.702893 13.2032 0.558231 13.1383 0.429153C13.0734 0.300076 12.9741 0.191636 12.8517 0.115878C12.7292 0.0401204 12.5882 9.11077e-06 12.4444 1.96556e-09H7.00001C6.87734 -8.7456e-06 6.7564 0.0291804 6.64708 0.085184C6.53776 0.141188 6.44315 0.222418 6.37099 0.322239L0.148805 8.9309C0.0644016 9.04765 0.0137397 9.18562 0.0024232 9.32954C-0.00889332 9.47346 0.0195769 9.61773 0.0846839 9.74639C0.149791 9.87504 0.248997 9.98307 0.371329 10.0585C0.49366 10.134 0.634347 10.1739 0.777829 10.1739H4.2957L1.61121 16.9267C1.54467 17.094 1.53758 17.2794 1.59114 17.4514C1.6447 17.6234 1.75563 17.7715 1.90518 17.8707C2.05474 17.97 2.23375 18.0142 2.41197 17.996C2.59019 17.9778 2.7567 17.8982 2.88336 17.7707L13.7722 6.81424C13.881 6.7048 13.955 6.56535 13.9851 6.41353C14.0151 6.26172 13.9997 6.10436 13.9408 5.96135C13.8819 5.81835 13.7822 5.69612 13.6543 5.61012C13.5264 5.52413 13.376 5.47823 13.2222 5.47824ZM4.46253 13.9678L6.16634 9.68191C6.21353 9.56319 6.2311 9.43463 6.21749 9.3075C6.20389 9.18037 6.15954 9.05852 6.08831 8.95263C6.01709 8.84673 5.92116 8.76001 5.80892 8.70005C5.69668 8.64008 5.57155 8.60871 5.44447 8.60866H2.30518L7.39609 1.56521H10.9083L7.81833 5.79754C7.73323 5.91411 7.6819 6.05213 7.67004 6.19628C7.65818 6.34043 7.68626 6.48506 7.75115 6.61412C7.81603 6.74319 7.9152 6.85163 8.03763 6.92742C8.16007 7.00321 8.30098 7.04337 8.44473 7.04345H11.3445L4.46253 13.9678Z"
							fill="#333333"
						/>
					</svg>
				);
				break;
			case 'update':
				svg = (
					<svg
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M17.3791 8.74495C18.0157 9.1988 18.8914 8.78231 18.9608 8.01955L19.3022 4.27438C19.3526 3.72263 18.9461 3.23461 18.3944 3.1843C17.8426 3.13399 17.3546 3.54054 17.3043 4.09223L17.2855 4.29887C15.608 2.11706 12.9934 0.700012 10.0065 0.700012C6.4696 0.700012 3.35464 2.71886 1.8091 5.74515C1.55711 6.23854 1.75285 6.84278 2.24623 7.09477C2.73966 7.34672 3.34383 7.15109 3.59582 6.65764C4.81256 4.27516 7.2542 2.70626 10.0065 2.70626C11.9317 2.70626 13.7137 3.47552 15.0254 4.75931C14.6784 4.7236 14.3226 4.87008 14.1054 5.17422C13.7835 5.62505 13.8879 6.25151 14.3387 6.57351L17.3791 8.74495ZM2.6266 11.255C1.98995 10.8012 1.11428 11.2177 1.04483 11.9805L0.703433 15.7256C0.653094 16.2774 1.05957 16.7654 1.61128 16.8157C2.16307 16.866 2.65103 16.4595 2.70133 15.9078L2.72017 15.7011C4.39765 17.8829 7.01223 19.3 9.99921 19.3C13.5361 19.3 16.651 17.2811 18.1966 14.2549C18.4486 13.7615 18.2528 13.1572 17.7594 12.9052C17.266 12.6533 16.6618 12.8489 16.4099 13.3424C15.1931 15.7248 12.7515 17.2937 9.99921 17.2937C8.07393 17.2937 6.29197 16.5245 4.9803 15.2407C5.32725 15.2764 5.68305 15.1299 5.90025 14.8258C6.22219 14.375 6.11776 13.7485 5.66696 13.4265L2.6266 11.255Z"
							fill="#333333"
						/>
					</svg>
				);
				break;
			case 'checkmark':
				svg = (
					<svg
						width="10"
						height="7"
						viewBox="0 0 10 7"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M1 3L4 6L9 1"
							stroke="#999999"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				);
				break;
			default:
				svg = null;
				break;
		}

		return (
			<button
				type="button"
				onClick={() => {
					onClick();
				}}
				className={cx(styles.button, styles[type], className)}
				disabled={!isEnabled}
			>
				{text} {svg} {children}
			</button>
		);
	}
}

export default Button;
