/* @flow */
import React, { Component } from 'react';
import { AppStoreContext } from '@Stores/AppStore';
import RangeSlider from '@Components/RangeSlider';
import Button from '@Components/Button';
import MapView from '@Components/MapView';
import blender from '@Utils/color';
import cx from 'classnames';
import styles from './index.module.scss';

type Props = {
	onConfirm: Function
};

type State = {
	value: string,
	valid: boolean,
	active: boolean,
	clicked: boolean,
	changed: boolean,
	currentValue: string
};

type Vertex = {
	x: number,
	y: number
};

type RefObject = { current: any };

class DirectionField extends Component<Props, State> {
	static contextType = AppStoreContext;
	wheel: RefObject;
	level: RefObject;
	moved: boolean;
	touch: Vertex;
	start: Vertex;
	angle: number;
	target: number;
	previous: number;
	interval: IntervalID;

	constructor(props: Props) {
		super(props);

		this.state = {
			value: '90',
			valid: false,
			active: false,
			clicked: false,
			changed: false,
			currentValue: ''
		};

		this.moved = false;
		this.wheel = React.createRef();
		this.level = React.createRef();
		this.start = { x: 0, y: 0 };
		this.touch = { x: 0, y: 0 };
		this.angle = 0;
		this.target = 0;
		this.previous = 0;
	}

	normalizeAngle(angle: number) {
		let a = angle;
		if (a > 180) {
			a -= 360;
		}
		if (a < -180) {
			a += 360;
		}
		return a;
	}

	setAngle() {
		if (this.moved) {
			const start = (Math.atan2(this.start.y, this.start.x) * 180) / Math.PI;
			const touch = (Math.atan2(this.touch.y, this.touch.x) * 180) / Math.PI;
			const value = this.normalizeAngle(touch - start);

			let angle = this.normalizeAngle(this.previous + value);
			if (angle > 90) {
				angle -= 180;
			}
			if (angle < -90) {
				angle += 180;
			}

			// this.target = angle;
			// angle = this.angle + (this.target - this.angle) * 0.15;
			// this.level.current.style.transform = `rotate(${angle}deg)`;
			this.setState({ value: String(Math.round(((-angle + 90) / 180) * 180)) });
			this.angle = angle;
		}
	}

	touchStart(e: TouchEvent) {
		const touches = e.touches[0];
		const bounds = this.wheel.current.getBoundingClientRect();
		const center = {
			x: bounds.left + this.wheel.current.offsetWidth / 2,
			y: bounds.top + this.wheel.current.offsetHeight / 2
		};
		this.moved = false;
		this.previous = this.angle;
		this.start = {
			x: center.x - touches.clientX,
			y: center.y - touches.clientY
		};

		this.wheel.current.addEventListener(
			'touchmove',
			this.touchMove.bind(this),
			{
				passive: false
			}
		);
		this.wheel.current.addEventListener('touchend', this.touchEnd.bind(this));

		clearInterval(this.interval);
		this.interval = setInterval(() => {
			this.setAngle();
		}, 10);

		this.activate();
	}

	touchMove(e: TouchEvent) {
		e.preventDefault();
		if (e.changedTouches && e.changedTouches.length) {
			const touches = e.changedTouches[0];
			const bounds = this.wheel.current.getBoundingClientRect();
			const center = {
				x: bounds.left + this.wheel.current.offsetWidth / 2,
				y: bounds.top + this.wheel.current.offsetHeight / 2
			};

			this.touch = {
				x: center.x - touches.clientX,
				y: center.y - touches.clientY
			};

			this.moved = true;
		}
	}

	touchEnd(e: TouchEvent) {
		this.moved = false;

		this.wheel.current.removeEventListener(
			'touchmove',
			this.touchMove.bind(this),
			{
				passive: false
			}
		);
		this.wheel.current.removeEventListener(
			'touchend',
			this.touchEnd.bind(this)
		);

		clearInterval(this.interval);
		/*
		const value = String(Math.round(((-this.angle + 90) / 180) * 100));
		this.setState({ value }, () => {
			this.validate();
		});
		*/
		this.validate();
	}

	getDirection() {
		const { value } = this.state;
		const num = Number(value);
		let dir = '';
		if (num < 36) {
			dir = 'Väst';
		} else if (num < 72) {
			dir = 'Sydväst';
		} else if (num < 108) {
			dir = 'Syd';
		} else if (num < 144) {
			dir = 'Sydöst';
		} else {
			dir = 'Öst';
		}
		return dir;
	}

	validate() {
		const { value, clicked, currentValue } = this.state;

		console.log('validate: ', value);

		if (clicked && currentValue !== value) {
			this.setState({ valid: true, changed: true });
		} else {
			this.setState({ valid: true });
		}
	}

	activate() {
		const { active } = this.state;
		if (!active) {
			this.setState({ active: true });
		}
	}

	confirm() {
		const { onConfirm } = this.props;
		const { value } = this.state;

		const orientation = Math.round(((90 - Number(value)) / 90) * 90);

		this.setState({
			clicked: true,
			changed: false,
			currentValue: value
		});

		onConfirm({
			direction: this.getDirection(),
			orientation: (Math.round(orientation / 10) * 10).toString()
		});
	}

	render() {
		const { onConfirm } = this.props;
		const { value, valid, active, clicked, changed } = this.state;
		const { location } = this.context;

		const angle = ((90 - Number(value)) / 90) * 90;
		const label = this.getDirection();
		const angleString =
			angle > 0 ? ` ${Math.round(angle)}°` : `${Math.round(angle)}° `;
		// const color = Math.abs((50 - Number(value)) / 50);
		// const alpha = Math.abs(color - 1) * 0.25 + 0.5;

		let button;
		if (clicked && changed) {
			button = (
				<Button
					text="Uppdatera"
					icon="update"
					className={cx(
						styles.confirm,
						valid ? styles.active : styles.inactive
					)}
					onClick={() => {
						this.confirm();
					}}
				/>
			);
		} else if (clicked) {
			button = (
				<Button
					text="Väderstreck"
					icon="checkmark"
					className={cx(styles.confirm, styles.inactive)}
					onClick={() => {
						this.confirm();
					}}
				/>
			);
		} else {
			button = (
				<Button
					text="Fortsätt"
					icon="continue"
					className={cx(
						styles.confirm,
						valid ? styles.active : styles.disabled
					)}
					onClick={() => {
						this.confirm();
					}}
				/>
			);
		}

		return (
			<div className={styles.directionField}>
				<label className={styles.label} htmlFor="range">
					<div className={styles.container}>
						<div className={styles.compass}>
							<div className={styles.compass__content}>
								<div className={styles.map}>
									{location && (
										<MapView latitude={location.lat} longitude={location.lng} />
									)}
									<div
										className={cx(
											styles.overlay,
											active ? styles.active : styles.inactive
										)}
									>
										<span
											className={cx(
												styles.sun,
												angle < 0 ? styles.negative : styles.positive
											)}
										>
											{angleString}
										</span>
										<span
											className={styles.level}
											style={{
												transform: `rotate(${angle}deg)`
											}}
											ref={this.level}
										/>
									</div>
								</div>
							</div>
						</div>
						<div
							ref={this.wheel}
							className={styles.wheel}
							onTouchStart={e => {
								this.touchStart(e);
							}}
						>
							<svg
								width="335"
								height="335"
								viewBox="0 0 335 335"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								style={{
									transform: `rotate(${angle}deg)`
								}}
							>
								<circle
									opacity="0.2"
									cx="167.5"
									cy="167.5"
									r="152.5"
									stroke="#DDDDDD"
									strokeWidth="30"
									strokeMiterlimit="16"
								/>
								<circle
									cx="167.5"
									cy="167.5"
									r="152.5"
									stroke="#DDDDDD"
									strokeWidth="10"
									strokeMiterlimit="16"
									strokeDasharray="1 19"
								/>
							</svg>
						</div>
					</div>
					<RangeSlider
						id="range"
						max={180}
						startValue={value}
						className={styles.range__input}
						onChange={val => {
							this.setState({ value: val });
						}}
						onRelease={() => this.validate()}
						onGrab={() => this.activate()}
					/>
				</label>
				<p className={styles.direction}>
					{!active ? (
						<span className={styles.unset}>Söder</span>
					) : (
						<span className={styles.set}>{label}</span>
					)}
				</p>
				<p className={styles.smallprint} />
				{button}
			</div>
		);
	}
}

export default DirectionField;
