/* @flow */
import React, { Component } from 'react';
import Button from '@Components/Button';
import InfoButton from '@Components/InfoButton';
import InfoModal from '@Components/InfoModal';
import cx from 'classnames';
import styles from './index.module.scss';

type Props = {
	// onUpdate: Function,
	onConfirm: Function,
	min?: number,
	max?: number
};

type State = {
	area: number,
	stories: number,
	valid: boolean,
	error: string,
	message: string,
	areaActive: boolean,
	storiesActive: boolean,
	clicked: boolean,
	changed: boolean,
	currentValue: number,
	roofMode: boolean,
	modal: boolean
};

type RefObject = { current: any };

class AreaField extends Component<Props, State> {
	static defaultProps = {
		min: -1,
		max: -1
	};

	areaInput: RefObject;
	storiesInput: RefObject;

	constructor(props: Props) {
		super(props);

		this.state = {
			area: 0,
			stories: 0,
			valid: false,
			areaActive: false,
			storiesActive: false,
			clicked: false,
			changed: false,
			currentValue: 0,
			roofMode: false,
			modal: false,
			error: '',
			message: ''
		};

		this.areaInput = React.createRef();
		this.storiesInput = React.createRef();
	}

	handleAreaChange(e: SyntheticInputEvent<HTMLInputElement>) {
		console.log('handleAreaChange', e.target.value);

		const { areaActive } = this.state;
		this.setState({ area: Number(e.target.value) }, () => {
			this.validate();
		});
		if (!areaActive) this.setState({ areaActive: true });
	}

	handleStoriesChange(e: SyntheticInputEvent<HTMLInputElement>) {
		console.log('handleStoriesChange', e.target.value);

		const { storiesActive } = this.state;
		this.setState({ stories: Number(e.target.value) }, () => {
			this.validate();
		});
		if (!storiesActive) this.setState({ storiesActive: true });
	}
	/*
	handleAdd() {
		const { stories, storiesActive } = this.state;

		console.log('add');
		this.setState({ stories: stories + 1 }, () => {
			this.validate();
		});
		if (!storiesActive) this.setState({ storiesActive: true });
	}

	handleSubtract() {
		console.log('subtract');
		const { stories } = this.state;
		if (stories > 1) {
			this.setState({ stories: stories - 1 }, () => {
				this.validate();
			});
		} else {
			this.setState({ stories: 1 }, () => {
				this.validate();
			});
		}
	}
	*/

	validate() {
		const { area, stories, clicked, currentValue, roofMode } = this.state;
		const { min, max } = this.props;

		let value: number = 0;
		let error: string = '';
		let message: string = '';
		let out = true;
		if (!roofMode && area !== 0 && stories !== 0) {
			value = Math.round(area / stories);
			if (Number(min) > -1 && value < Number(min)) {
				out = false;
				error = '< 25';
				message =
					'Din yta verkar vara för liten. Kontakta oss gärna så hjälper vi dig vidare.';
			}
			if (Number(max) > -1 && value > Number(max)) {
				out = false;
				error = '> 230';
				message =
					'Din yta verkar vara relativt stor, kontakta oss gärna så hjälper vi dig med en bra lösning.';
			}
		} else if (roofMode && area !== 0) {
			value = area;
			if (Number(min) > -1 && value < Number(min)) {
				out = false;
				error = '< 25';
				message =
					'Din yta verkar vara för liten. Kontakta oss gärna så hjälper vi dig vidare.';
			}
			if (Number(max) > -1 && value > Number(max)) {
				out = false;
				error = '> 230';
				message =
					'Din yta verkar vara relativt stor, kontakta oss gärna så hjälper vi dig med en bra lösning.';
			}
		} else {
			out = false;
		}

		if (out) {
			if (clicked && currentValue !== value) {
				this.setState({ valid: out, changed: true, error: '', message: '' });
			} else {
				this.setState({ valid: out, error: '', message: '' });
			}
		} else {
			this.setState({ valid: out, error, message });
		}
		/*
		if (out) {
			onUpdate({ area: area / stories });
		}
		*/
	}

	confirm() {
		const { onConfirm } = this.props;
		const { area, stories, roofMode } = this.state;

		let total = 0;
		if (roofMode) {
			total = area;
		} else {
			total = area && stories ? Math.round(area / stories) : 0;
		}

		this.setState({
			clicked: true,
			changed: false,
			currentValue: total
		});

		onConfirm({
			area: total
		});
	}

	showInfoModal(flag: boolean) {
		this.setState({ modal: flag });
	}

	render() {
		const {
			area,
			stories,
			valid,
			areaActive,
			storiesActive,
			roofMode,
			clicked,
			changed,
			modal,
			error,
			message
		} = this.state;

		let button;
		if (clicked && changed) {
			button = (
				<Button
					text="Uppdatera"
					icon="update"
					className={cx(
						styles.confirm,
						valid ? styles.active : styles.inactive
					)}
					onClick={() => {
						this.confirm();
					}}
				/>
			);
		} else if (clicked) {
			button = (
				<Button
					text="Yta"
					icon="checkmark"
					className={cx(styles.confirm, styles.inactive)}
					onClick={() => {
						this.confirm();
					}}
				/>
			);
		} else {
			button = (
				<Button
					text="Fortsätt"
					icon="continue"
					className={cx(
						styles.confirm,
						valid ? styles.active : styles.disabled
					)}
					onClick={() => {
						this.confirm();
					}}
				/>
			);
		}

		/* TODO: replace with real copy, this is just a placeholder
		const info = {
			title: 'Placeholder info',
			text: 'Lorem ipsum dolor sit amet'
		};
		*/
		const info = false;

		let total = 0;
		if (roofMode) {
			total = area;
		} else {
			total = area && stories ? Math.round(area / stories) : 0;
		}

		return (
			<div className={styles.areaField}>
				<div className={styles.content}>
					<div
						className={cx(
							styles.inputSection,
							areaActive ? styles.active : styles.inactive
						)}
					>
						<label className={styles.label} htmlFor="fields_area">
							<span className={styles.text}>Din </span>
							<button
								className={cx(styles.text, !roofMode ? null : styles.inactive)}
								onClick={() => {
									this.setState({ roofMode: false }, () => {
										this.validate();
									});
								}}
								type="button"
							>
								boyta
							</button>
							<span className={styles.divider}>/</span>
							<button
								className={cx(styles.text, roofMode ? null : styles.inactive)}
								onClick={() => {
									this.setState({ roofMode: true }, () => {
										this.storiesInput.current.value = '';
										this.setState({ stories: 0 }, () => {
											this.validate();
										});
									});
								}}
								type="button"
							>
								takyta
							</button>
							<div className={styles.inputContainer}>
								<input
									id="fields_area"
									min="0"
									ref={this.areaInput}
									type="number"
									placeholder="0"
									onFocus={() => {
										this.areaInput.current.select();
									}}
									onChange={e => {
										this.handleAreaChange(e);
									}}
									className={styles.input}
								/>
								<span className={styles.unit}>
									m<sup>2</sup>
								</span>
							</div>
							<div className={styles.checkmark} />
						</label>
					</div>
					<div
						className={cx(
							styles.inputSection,
							storiesActive ? styles.active : styles.inactive,
							roofMode ? styles.disabled : ''
						)}
					>
						<label className={styles.label} htmlFor="fields_stories">
							<span className={styles.text}>Antal våningar</span>
							<div className={styles.inputContainer}>
								<input
									id="fields_stories"
									min="0"
									ref={this.storiesInput}
									type="number"
									// value={stories}
									placeholder="0"
									onFocus={() => {
										this.storiesInput.current.select();
									}}
									onChange={e => {
										this.handleStoriesChange(e);
									}}
									className={styles.input}
									disabled={roofMode ? 'disabled' : false}
								/>

								<span className={styles.unit}>vån</span>
							</div>
							<div className={styles.checkmark} />
						</label>
					</div>
				</div>
				<div className={styles.background}>
					{info && modal && (
						<InfoModal
							title={info.title}
							text={info.text}
							onClose={() => {
								this.showInfoModal(false);
							}}
						/>
					)}
					<div className={styles.output}>
						<h3 className={styles.heading}>
							<span>Estimerad takyta</span>
							{info && <InfoButton onClick={() => this.showInfoModal(true)} />}
						</h3>
						{!valid && error !== '' ? (
							<p className={cx(styles.text, styles.error)}>
								<span className={styles.number}>{error}</span>
								<span className={styles.unit}>
									m<sup>2</sup>
								</span>
							</p>
						) : (
							<p className={styles.text}>
								<span className={styles.number}>{total}</span>
								<span className={styles.unit}>
									m<sup>2</sup>
								</span>
							</p>
						)}
					</div>
					<small className={styles.info}>{message}</small>
					{button}
				</div>
			</div>
		);
	}
}

export default AreaField;
