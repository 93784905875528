const config = {
	defaultZoom: 20,
	maxZoom: 20,
	minZoom: 4,
	panControl: false,
	streetViewControl: false,
	overviewMapControl: false,
	rotateControl: false,
	tilt: 0,
	mapTypeControl: false,
	zoomControl: false,
	scaleControl: false,
	scrollwheel: false,
	disableDoubleClickZoom: true,
	fullscreenControl: false,
	mapTypeId: 'satellite',
	gestureHandling: 'greedy'
};

// gestureHandling: 'none'

export default config;
