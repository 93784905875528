import React from 'react';
import { render } from 'react-dom';
import AppStore from '@Stores/AppStore';
import App from './App';

render(
	<AppStore>
		<App />
	</AppStore>,
	document.getElementById('root')
);

if (module.hot) {
	module.hot.accept();
}
