/* @flow */
import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import cx from 'classnames';
import Marker from './Marker';
import config from './config';
import styles from './index.module.scss';

type Props = {
	longitude: number,
	latitude: number,
	marker?: boolean,
	overview?: boolean,
	className?: string,
	onPositionChange?: Function
};

class MapView extends Component<Props> {
	static defaultProps = {
		className: '',
		marker: false,
		overview: false,
		onPositionChange: null
	};

	render() {
		const {
			className,
			marker,
			overview,
			onPositionChange,
			longitude,
			latitude
		} = this.props;
		const { defaultZoom } = config;

		return (
			<div className={cx(styles.mapView, className)}>
				{longitude && latitude && (
					<GoogleMapReact
						bootstrapURLKeys={{
							key: 'AIzaSyC_p6kw5msUgG8Vw0lY_FyB6qciZw22pGQ'
						}}
						center={{ lat: latitude, lng: longitude }}
						defaultZoom={defaultZoom}
						options={config}
						zoom={overview ? 4.5 : defaultZoom}
						onDragEnd={map => {
							console.log(map.center.lat(), map.center.lng());

							if (onPositionChange) {
								onPositionChange({
									lat: map.center.lat(),
									lng: map.center.lng()
								});
							}
						}}
					/>
				)}
				{marker && <Marker lat={latitude} lng={longitude} />}
			</div>
		);
	}
}

export default MapView;
