/* @flow */
import React, { Component } from 'react';
import SearchField from '@Components/SearchField';
import MapView from '@Components/MapView';
import Button from '@Components/Button';
import cx from 'classnames';
import styles from './index.module.scss';

type Props = {
	// onUpdate: Function,
	onConfirm: Function,
	address?: string
};

type State = {
	valid: boolean,
	longitude: number,
	latitude: number,
	address: string,
	postalCode: string,
	clicked: boolean,
	changed: boolean,
	positionChanged: boolean,
	currentAddress: string
};

class AddressField extends Component<Props, State> {
	static defaultProps = {
		address: ''
	};

	constructor(props: Props) {
		super(props);

		const { address } = this.props;

		this.state = {
			valid: false,
			longitude: 0,
			latitude: 0,
			address: address || '',
			postalCode: '',
			clicked: false,
			changed: false,
			positionChanged: false,
			currentAddress: ''
		};
	}

	componentDidMount() {
		const { address, latitude, longitude } = this.state;

		console.log('componentDidMount', address, latitude, longitude);

		this.validate();
	}

	onLocationChange(address: string, loc: Object) {
		console.log('onLocationChange:', address, loc);

		this.setState(
			{
				address,
				latitude: loc.lat,
				longitude: loc.lng,
				valid: false
			},
			() => {
				this.validate();
			}
		);
	}

	onPositionChange(loc: Object) {
		console.log('onPositionChange:', loc);
		this.setState(
			{
				latitude: loc.lat,
				longitude: loc.lng,
				valid: false,
				positionChanged: true
			},
			() => {
				this.validate();
			}
		);
	}

	validate() {
		// const { onUpdate } = this.props;
		const {
			clicked,
			address,
			latitude,
			longitude,
			currentAddress,
			positionChanged
		} = this.state;
		console.log('validate: ', address, latitude, longitude);
		if (address !== '' && latitude !== null && longitude !== null) {
			console.log('is valid');

			if ((clicked && currentAddress !== address) || positionChanged) {
				this.setState({ valid: true, changed: true });
			} else {
				this.setState({ valid: true });
			}
		} else {
			this.setState({ valid: false });
		}
	}

	confirm() {
		const { onConfirm } = this.props;
		const { address, latitude, longitude } = this.state;

		this.setState({
			clicked: true,
			changed: false,
			positionChanged: false,
			currentAddress: address
		});

		onConfirm({
			address,
			location: { lat: latitude, lng: longitude }
		});
	}

	render() {
		const {
			address,
			longitude,
			latitude,
			valid,
			postalCode,
			clicked,
			changed
		} = this.state;

		let button;
		if (clicked && changed) {
			button = (
				<Button
					text="Uppdatera"
					icon="update"
					className={cx(
						styles.confirm,
						valid ? styles.active : styles.inactive
					)}
					onClick={() => {
						this.confirm();
					}}
				/>
			);
		} else if (clicked) {
			button = (
				<Button
					text="Gatuadress"
					icon="checkmark"
					className={cx(styles.confirm, styles.inactive)}
					onClick={() => {
						this.confirm();
					}}
				/>
			);
		} else {
			button = (
				<Button
					text="Fortsätt"
					icon="continue"
					className={cx(
						styles.confirm,
						valid ? styles.active : styles.disabled
					)}
					onClick={() => {
						this.confirm();
					}}
				/>
			);
		}

		return (
			<div className={styles.addressField}>
				<div className={styles.locationContent}>
					<SearchField
						className={valid ? styles.valid : styles.invalid}
						address={address}
						postalCode={postalCode}
						addressChange={(addr: string, loc: Object) => {
							this.onLocationChange(addr, loc);
						}}
					/>
				</div>
				<div className={styles.location}>
					{longitude && latitude ? (
						<MapView
							longitude={longitude}
							latitude={latitude}
							marker
							onPositionChange={(loc: Object) => {
								this.onPositionChange(loc);
							}}
						/>
					) : (
						<MapView
							longitude={16}
							latitude={64}
							marker
							overview
							onPositionChange={(loc: Object) => {
								this.onPositionChange(loc);
							}}
						/>
					)}
				</div>
				{button}
				<p className={styles.smallprint}>
					Tips! Om markören står på fel hus så kan du justera i bilden.
				</p>
			</div>
		);
	}
}

export default AddressField;
