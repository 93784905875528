/* @flow */
import React, { Component } from 'react';
import InfoIcon from '@Src/assets/icons/info.svg';
import cx from 'classnames';
import styles from './index.module.scss';

type Props = {
	onClose: Function,
	title: string,
	text: string,
	className?: string
};

class InfoModal extends Component<Props> {
	static defaultProps = {
		className: ''
	};

	render() {
		const { onClose, className, title, text } = this.props;

		return (
			<div className={cx(styles.infoModal, className)}>
				<div className={styles.header}>
					<img src={InfoIcon} alt="info" />
					<button
						className={styles.closeButton}
						type="button"
						onClick={() => {
							onClose();
						}}
					>
						✕
					</button>
				</div>
				<h3 className={styles.title}>{title}</h3>
				<p className={styles.text}>{text}</p>
			</div>
		);
	}
}

export default InfoModal;
