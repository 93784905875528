/* @flow */
import React, { Component } from 'react';
import cx from 'classnames';
import InfoIcon from '@Src/assets/icons/info.svg';
import CostIcon from './icons/cost.svg';
import ProductionIcon from './icons/production.svg';
import SavingIcon from './icons/saving.svg';
import styles from './index.module.scss';

type Props = {
	onClose: Function,
	icon: string,
	title: string,
	text: Array<string>,
	className?: string
};

class InfoOverlay extends Component<Props> {
	static defaultProps = {
		className: ''
	};

	render() {
		const { onClose, className, icon, title, text } = this.props;

		let currentIcon = '';
		if (icon === 'cost' || icon === 'costNet') {
			currentIcon = CostIcon;
		}
		// else if (icon === 'production') {
		// 	currentIcon = ProductionIcon;
		// }
		else if (icon === 'saving') {
			currentIcon = SavingIcon;
		} else {
			currentIcon = InfoIcon;
		}

		return (
			<div className={cx(styles.infoOverlay, className)}>
				<div className={styles.header}>
					<img src={currentIcon} alt="" />
					<button
						className={styles.closeButton}
						type="button"
						onClick={() => {
							onClose();
						}}
					>
						✕
					</button>
				</div>
				<h3 className={styles.title}>{title}</h3>
				{text.map(p => (
					<p className={styles.text} key={p.substr(0)}>
						{p}
					</p>
				))}
			</div>
		);
	}
}

export default InfoOverlay;
