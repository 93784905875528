/* @flow */
import React, { Component } from 'react';
import { AppStoreContext } from '@Stores/AppStore';
import FormSection from '@Components/FormSection';
import AddressField from '@Components/fields/AddressField';
import AreaField from '@Components/fields/AreaField';
import AngleField from '@Components/fields/AngleField';
import DirectionField from '@Components/fields/DirectionField';
import AddressIcon from '@Src/assets/icons/address.svg';
import AreaIcon from '@Src/assets/icons/area.svg';
import DirectionIcon from '@Src/assets/icons/direction.svg';
import AngleIcon from '@Src/assets/icons/angle.svg';
import scrollToComponent from 'react-scroll-to-component';
import styles from './index.module.scss';

type Props = {};

type RefObject = { current: any };

type State = {
	next: string,
	loaded: boolean
};

class Form extends Component<Props, State> {
	static contextType = AppStoreContext;
	address: RefObject;
	area: RefObject;
	angle: RefObject;
	direction: RefObject;

	constructor(props: Props) {
		super(props);

		this.state = {
			next: '',
			loaded: false
		};

		this.address = React.createRef();
		this.area = React.createRef();
		this.angle = React.createRef();
		this.direction = React.createRef();
	}

	onLocation(address: string, location: Object) {
		const { setLocation } = this.context;
		setLocation(address, location);
	}

	scrollToRef(ref: RefObject) {
		ref.current.show();

		this.setState({ next: '' }, () => {
			setTimeout(() => {
				scrollToComponent(ref.current, {
					align: 'top',
					offset: 120,
					duration: 700,
					easing: 'inOutCubic'
				});
			}, 500);
		});
	}

	render() {
		const { title, register, complete, address, location } = this.context;
		const { next, loaded } = this.state;

		return (
			<>
				<div className={styles.form}>
					<FormSection
						ref={this.address}
						icon={AddressIcon}
						title="Din gatuadress"
						text="Ange din adress så får du hjälp att uppskatta ytan på ditt tak. Du kan alltid ändra adressen om det blivit fel."
						visible
					>
						<AddressField
							address={address}
							onConfirm={val => {
								this.onLocation(val.address, val.location);
								register(val);
								this.scrollToRef(this.area);
								console.log('onConfirm:', val.address, val.location);
							}}
						/>
					</FormSection>
					<FormSection
						ref={this.area}
						icon={AreaIcon}
						title="Ange yta"
						text="Vet du inte hur stor takyta du har? Testa dig fram genom att ange din boyta istället."
					>
						<AreaField
							onConfirm={val => {
								register(val);
								this.scrollToRef(this.angle);
							}}
							min={25}
							max={230}
						/>
					</FormSection>
					<FormSection
						ref={this.angle}
						icon={AngleIcon}
						title="Takets lutning"
						text="Olika lutningar innebär olika solexponering — Välj om du har platt eller vinklat tak."
					>
						<AngleField
							onConfirm={val => {
								register(val);
								this.scrollToRef(this.direction);
							}}
						/>
					</FormSection>
					<FormSection
						ref={this.direction}
						icon={DirectionIcon}
						title="Väderstreck°"
						text="Vilken riktning har din takyta? Linjera det gula strecket med taknocken."
					>
						<DirectionField
							onConfirm={val => {
								register(val);
								complete();
							}}
						/>
					</FormSection>
				</div>
			</>
		);
	}
}

export default Form;
