/* @flow */
import React, { Component } from 'react';
import { AppStoreContext } from '@Stores/AppStore';
import cx from 'classnames';
import styles from './index.module.scss';

type Props = {
	title: string,
	text: string,
	className?: string
};

class Notification extends Component<Props> {
	static contextType = AppStoreContext;
	static defaultProps = {
		className: ''
	};

	componentDidMount() {
		const { updateNotified } = this.context;
	}

	render() {
		const { title, text, className } = this.props;
		const { inputUpdated } = this.context;

		return (
			inputUpdated > -1 && (
				<div key={inputUpdated} className={cx(styles.notification, className)}>
					<h3 className={styles.title}>{title}</h3>
					<p className={styles.text}>{text}</p>
				</div>
			)
		);
	}
}

export default Notification;
