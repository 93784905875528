/* @flow */
import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import InfoButton from '@Components/InfoButton';
import InfoOverlay from '@Components/InfoOverlay';
import Button from '@Components/Button';
import cx from 'classnames';
import styles from './index.module.scss';

/*
type Product = {
	title: string, // Card title like "Allsidig" or "Ekonomi"
	alias: string, // Utellus alias like "Ultra" or "Gamma"
	label: string, // Product name like "Trina Solar 285W"
	price: number, // Unit price per panel in sek
	watts: number, // Power rating in watts
	image: string, // Product header image
	warranty: number, // Warranty in years
	link: string // Link to product page
};
*/

type Product = {
	antal_paneler: string,
	arsproduktion: string,
	aterbetalningstid: string,
	avkastning: string,
	besparing: string,
	image: string,
	pris: string,
	produktgaranti: string,
	tillverkare: string,
	url: string
};

type Props = {
	product: Product,
	className?: string,
	onMouseEnter: Function,
	onFocus: Function,
	isCurrent: boolean,
	usp: string,
	info: Object,
	onCtaClick: Function
};

type State = {
	currentInfo: string,
	modal: boolean
};

class ProductCard extends Component<Props, State> {
	static defaultProps = {
		className: ''
	};

	constructor(props: Props) {
		super(props);

		this.state = {
			currentInfo: '',
			modal: false
		};
	}

	addSpacesToNumber(x: number) {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	}

	showInfoModal(flag: boolean, content: string) {
		this.setState({
			modal: flag,
			currentInfo: content
		});
	}

	render() {
		const {
			product,
			usp,
			info,
			className,
			onMouseEnter,
			onFocus,
			isCurrent,
			onCtaClick
		} = this.props;
		const { currentInfo, modal } = this.state;

		const currentClassName = isCurrent ? styles.current : styles.notCurrent;
		const modalClassName = modal ? styles.modalOpen : undefined;

		return (
			<div
				className={cx(styles.productCard, currentClassName, modalClassName)}
				onMouseEnter={() => onMouseEnter()}
				onFocus={() => onFocus()}
			>
				<div className={cx(styles.content, className)}>
					<p className={styles.intro}>{usp}</p>
					<div className={cx(styles.details, styles.yearProd)}>
						<h3 className={styles.detailsTitle}>Årsproduktion</h3>
						<p className={cx(styles.detailsValue, styles.large)}>
							{product.arsproduktion.slice(0, -4)}
							<span className={styles.unit}>
								{product.arsproduktion.slice(-3)}
							</span>
						</p>
					</div>
					<div className={cx(styles.details, styles.cost)}>
						<h3 className={cx(styles.detailsTitle, styles.muted)}>
							{info.cost.title}
							{info.cost && (
								<div className={styles.info}>
									<InfoButton
										onClick={() => {
											this.showInfoModal(true, 'cost');
										}}
									/>
								</div>
							)}
						</h3>
						<p className={styles.detailsValue}>
							<span className={cx(styles.price, styles.lineThrough)}>
								{product.pris.slice(0, -3)}
							</span>
							<span className={cx(styles.unit, styles.muted)}>sek</span>
						</p>

						<h3 className={styles.detailsTitle}>
							{info.costNet.title}
							{info.costNet && (
								<div className={styles.info}>
									<InfoButton
										onClick={() => {
											this.showInfoModal(true, 'costNet');
										}}
									/>
								</div>
							)}
						</h3>
						<p className={styles.detailsValue}>
							{product.pris.slice(0, -3)}
							<span className={styles.unit}>sek</span>
						</p>
					</div>
					<div className={cx(styles.details, styles.saving)}>
						<h3 className={styles.detailsTitle}>
							{info.saving.title}, år 1
							{info.saving && (
								<div className={styles.info}>
									<InfoButton
										onClick={() => {
											this.showInfoModal(true, 'saving');
										}}
									/>
								</div>
							)}
						</h3>
						<p className={styles.detailsValue}>
							{product.besparing.slice(0, -3)}
							<span className={styles.unit}>sek</span>
						</p>
					</div>

					{info && modal && (
						<InfoOverlay
							className={styles.modal}
							icon={currentInfo}
							title={info[currentInfo].title}
							text={info[currentInfo].text}
							onClose={() => {
								this.showInfoModal(false, '');
							}}
						/>
					)}
					<figure className={styles.image}>
						<img src={product.image} alt={product.tillverkare} />
					</figure>
					<Button
						text="Maila sammanställning"
						className={styles.button}
						onClick={() => onCtaClick()}
					/>
				</div>
			</div>
		);
	}
}

export default ProductCard;
