/* @flow */
import React, { Component } from 'react';
import { AppStoreContext } from '@Stores/AppStore';
import ProductCard from '@Components/ProductCard';
import Button from '@Components/Button';
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel
} from 'react-accessible-accordion';
import cx from 'classnames';
import ContactForm from '@Components/ContactForm';
import ProductsIcon from '@Src/assets/icons/products.svg';
import LightbulbIcon from '@Src/assets/icons/lightbulb.svg';
import FormIcon from '@Src/assets/icons/form.svg';
import Slider from 'react-slick';
import scrollToComponent from 'react-scroll-to-component';
import styles from './index.module.scss';

type Props = {};

type State = {
	recommended: boolean,
	currentSlide: number,
	scrolledTo: boolean
};

type RefObject = { current: any };

class Products extends Component<Props, State> {
	static contextType = AppStoreContext;
	recommendation: RefObject;
	contact: RefObject;
	slider: RefObject;

	constructor(props: Props) {
		super(props);

		this.state = {
			recommended: false,
			currentSlide: 1,
			scrolledTo: false
		};

		this.recommendation = React.createRef();
		this.contact = React.createRef();
		this.slider = React.createRef();
	}

	componentDidUpdate = () => {
		const { result, inputComplete, inputUpdated } = this.context;
		const { scrolledTo } = this.state;

		if (
			!scrolledTo &&
			result.length > 0 &&
			inputComplete &&
			inputUpdated === -1
		) {
			this.setState({ scrolledTo: true }, () => {
				setTimeout(() => {
					scrollToComponent(this.recommendation.current, {
						align: 'top',
						offset: 120,
						duration: 500
					});
				}, 500);
			});
		}
	};

	scrollToRef = (ref: RefObject) => {
		setTimeout(() => {
			scrollToComponent(ref.current, {
				align: 'top',
				offset: -140,
				duration: 700,
				easing: 'inOutCubic'
			});
		}, 500);
	};

	products() {
		const { recommended, currentSlide } = this.state;
		const { result, getProductInfo } = this.context;
		const productInfo = getProductInfo();

		return result.map((product, i) => {
			return (
				<ProductCard
					recommended={recommended}
					className={cx(styles.card, styles[`card${i}`])}
					product={product}
					onMouseEnter={() => this.setState({ currentSlide: i })}
					onFocus={() => this.setState({ currentSlide: i })}
					onCtaClick={() => this.scrollToRef(this.contact)}
					key={product.title}
					isCurrent={i === currentSlide}
					usp={productInfo[i].usp}
					info={productInfo[i].info}
				>
					Another Slide
				</ProductCard>
			);
		});
	}

	goToSlide(slideIndex: number) {
		this.setState({ currentSlide: slideIndex });
		this.slider.current.slickGoTo(slideIndex);
	}

	render() {
		const { result, getProductInfo, submitComplete } = this.context;
		const { currentSlide } = this.state;

		const productInfo = getProductInfo();

		const headings = [];
		productInfo.forEach(product => {
			headings.push(product.alias);
		});

		const classes = cx({
			[styles.products]: true,
			[styles[`currentSlide${currentSlide}`]]: true
		});

		const settings = {
			dots: false,
			infinite: false,
			arrows: false,
			speed: 250,
			autoplay: false,
			slidesToShow: 3,
			slidesToScroll: 1,
			className: styles.productSlider,
			initialSlide: 1,

			responsive: [
				{
					breakpoint: 1000,
					settings: {
						dots: false,
						focusOnSelect: true,
						afterChange: index => {
							this.setState({ currentSlide: index });
						}
					}
				},
				{
					breakpoint: 899,
					settings: {
						focusOnSelect: true,
						slidesToShow: 1,
						centerMode: true,
						centerPadding: '140px',
						afterChange: index => {
							this.setState({ currentSlide: index });
						}
					}
				},
				{
					breakpoint: 819,
					settings: {
						focusOnSelect: true,
						slidesToShow: 1,
						centerMode: true,
						centerPadding: '80px',
						afterChange: index => {
							this.setState({ currentSlide: index });
						}
					}
				},
				{
					breakpoint: 599,
					settings: {
						focusOnSelect: true,
						slidesToShow: 1,
						centerMode: true,
						centerPadding: '15px',
						afterChange: index => {
							this.setState({ currentSlide: index });
						}
					}
				}
			]
		};

		return (
			result.length > 0 && (
				<>
					<div className={classes} ref={this.recommendation}>
						<img className={styles.icon} src={ProductsIcon} alt="" />
						<img className={styles.iconBg} src={ProductsIcon} alt="" />

						<h4 className={styles.title}>Tre favoriter</h4>
						<p className={styles.text}>
							Här är tre bra alternativ för dig. Fyll i din mail så skickar vi
							sammanställningen och ger en fri rådgivning.
						</p>
						<div className={styles.productHeadings}>
							{headings.map((heading, i) => (
								<button
									type="button"
									className={currentSlide === i ? styles.active : undefined}
									key={heading}
									onMouseEnter={() => this.setState({ currentSlide: i })}
									onFocus={() => this.setState({ currentSlide: i })}
									onClick={() => this.goToSlide(i)}
									onKeyDown={() => this.goToSlide(i)}
								>
									{heading}
								</button>
							))}
							<div className={styles.underline} />
						</div>
						<div className={styles.productsContent}>
							<div className={styles.slider}>
								<Slider {...settings} ref={this.slider}>
									{this.products()}
								</Slider>
							</div>
							<Button
								text="Maila sammanställning"
								className={styles.desktopButton}
								onClick={() => this.scrollToRef(this.contact)}
							/>
						</div>
					</div>
					<div className={styles.notes}>
						<img className={styles.icon} src={LightbulbIcon} alt="" />
						<p className={styles.paragraph}>
							<strong>Psst!</strong> Vi mailar gärna en komplett sammanställning
							av din solvärdering samt erbjuder fri rådgivning och offert. Helt
							gratis!
						</p>

						<Accordion
							allowMultipleExpanded
							allowZeroExpanded
							className={styles.accordion}
						>
							<AccordionItem className={styles.accordion__item}>
								<AccordionItemHeading className={styles.accordion__heading}>
									<AccordionItemButton className={styles.accordion__button}>
										Fri sammanställning
									</AccordionItemButton>
								</AccordionItemHeading>
								<AccordionItemPanel className={styles.accordion__panel}>
									<p>
										Vi skickar med tre förslag på solpaneler och hjälper dig
										gärna vidare för att göra en skräddarsydd lösning för just
										ditt tak.
									</p>
								</AccordionItemPanel>
							</AccordionItem>
							<AccordionItem className={styles.accordion__item}>
								<AccordionItemHeading className={styles.accordion__heading}>
									<AccordionItemButton className={styles.accordion__button}>
										Fri rådgivning
									</AccordionItemButton>
								</AccordionItemHeading>
								<AccordionItemPanel className={styles.accordion__panel}>
									<p>
										Alla tak är olika och har olika förutsättningar. Våra
										solrådgivare hjälper dig att göra en komplett analys av dina
										förutsättningar för att du ska få ut så stor effekt som
										möjligt av ditt tak.
									</p>
								</AccordionItemPanel>
							</AccordionItem>
							<AccordionItem className={styles.accordion__item}>
								<AccordionItemHeading className={styles.accordion__heading}>
									<AccordionItemButton className={styles.accordion__button}>
										Fri offert
									</AccordionItemButton>
								</AccordionItemHeading>
								<AccordionItemPanel className={styles.accordion__panel}>
									<ul>
										<li>Paneltyp</li>
										<li>Årsproduktion</li>
										<li>Kostnad</li>
										<li>Besparing, År 1</li>
										<li>Återbetalningstid</li>
										<li>Avkastning, År 1</li>
										<li>Produktgaranti</li>
									</ul>
								</AccordionItemPanel>
							</AccordionItem>
						</Accordion>
					</div>
					<div className={styles.contact}>
						<img className={styles.icon} src={FormIcon} alt="" />
						<p className={styles.paragraph}>
							Fyll i dina uppgifter så återkommer vi med din fria sammanställng
							samt rådgivning och offert om så önskas.
						</p>
						<ContactForm ref={this.contact} isSent={submitComplete} />
					</div>
				</>
			)
		);
	}
}

export default Products;
