/* @flow */
import React, { Component } from 'react';
import InfoIcon from '@Src/assets/icons/info.svg';
import styles from './index.module.scss';

type Props = {
	onClick: Function
};

class InfoButton extends Component<Props> {
	render() {
		const { onClick } = this.props;

		return (
			<button
				type="button"
				onClick={() => {
					onClick();
				}}
				className={styles.infoButton}
			>
				<img src={InfoIcon} alt="info" className={styles.infoButton} />
			</button>
		);
	}
}

export default InfoButton;
